<template>
    <section class="tables">
      
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="card-title">Tous les roles</div>
                 <nav aria-label="breadcrumb" class="float-right">
                <ol class="breadcrumb">
                  <router-link to="addrole">
                    <li class="breadcrumb-item btn btn-primary">
                      Ajouter  role
                    </li>
                  </router-link>
                </ol>
              </nav>
              
              <div class="table-responsive">
                <input
                  value=""
                  class="form-control search-input"
                  placeholder="Recherche par intitulé"
                  type="text"
                  v-model="search"
                />
              <table class="table">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Permissions</th>
                    <th>Actions</th>
  
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(role, key) in searchFunction" :key="key">
                    <td>{{ role.nom }}</td>
                    <td>
                        <i
                        class="mdi mdi-eye icone text-info"
                        title="Afficher les détails"
                        data-toggle="modal"
                        :data-target="'#test' + role._id"
                      >
                      </i>

                      <div
                        class="modal fade"
                        :id="'test' + role._id"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel">
                                <span style="font-size: 13px"
                                  >Role({{role.nom}})</span
                                >
                                <b></b>
                              </h5>
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                @click.prevent="close()"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                          

                              <div
                                style="margin-top: 20px; margin-bottom: 20px"
                              >
                       
                              </div>
                              <table class="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>Les permissions du role : </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr v-for="(permission, key) in role.permissions" :key="key">
                          
                                 <th>
                                  
                                 {{permission.titre}}
                                 
                                </th>

                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>

  
                    <td >
                      <router-link
                        :to="{ name: 'editrole', params: { id: role._id } }"
                        ><i 
                        class="mdi mdi-pen icone text-success" style="color:green"></i></router-link
                      >&nbsp;
                      <i 
                        class="mdi mdi-delete icone text-danger"  style="color:red"
                        
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { HTTP } from '@/axios';
  import Swal from "sweetalert2";
  import Toast from "sweetalert2";
  export default {
    data() {
      return {
        roles: [],
        search:"",
      };
    },
        computed: {
      searchFunction() {
        return this.roles.filter((item) => {
          return (
            item.nom
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) > -1
          );
        });
      },
    },
  
    created() {
      this.getroles();
    },
    methods: {
        getroles() {
        HTTP
          .get("roles/getroles")
          .then((response) => {
            this.roles = response.data;
          })
          .then((err) => console.log(err));
      },
  
       deleteproduit(id) {
        Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "Vous voulez supprimé la zone",
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {
            HTTP
              .delete("roles/deletezone/" + id)
              .then((response) => {
                //this.$htmlToPaper("printNow");
  
                Toast.fire({
                  position: "top-center",
                  icon: "success",
                  title: "Zone supprimé",
                });
  
                this.getroles();
              });
          }
        });
      },
    },
  };
  </script>
  